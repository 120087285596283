/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconSaveOutlineSvgIcon: SVGIcon = {
  name: 'save_outline',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Outline/save-outline</title><g id="Icon/Outline/save-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="save"><path d="M16.5,1.50000001 L16.5,7.50598728 C16.5,8.33110773 15.830533,9.00000001 15.0036831,9.00000001 L4.49631699,9.00000001 C3.66992394,9.00000001 3.00000001,8.32486788 3.00000001,7.50598728 L3.00000001,1.50000001 L1.50000001,1.50000001 L1.50000001,22.5 L3.00000001,22.5 L3.00000001,13.4955453 C3.00000001,12.6695785 3.67276969,12 4.50370996,12 L19.4962901,12 C20.3267662,12 21,12.6708854 21,13.4955453 L21,22.5 L22.5,22.5 L22.5,6.62132037 L17.3786797,1.50000001 L16.5,1.50000001 Z M1.5,0 L17.3786797,0 C17.7765045,0 18.1580354,0.158035261 18.4393399,0.43933983 L23.5606603,5.56066019 C23.8419648,5.84196476 24,6.22349564 24,6.62132037 L24,22.5 C24,23.3284272 23.3284272,24 22.5,24 L1.5,24 C0.671572878,24 0,23.3284272 0,22.5 L0,1.5 C0,0.671572878 0.671572878,0 1.5,0 Z M4.50000002,22.5044549 C4.50000002,22.5011577 9.49876339,22.4996728 19.4962901,22.5000001 C19.4983648,22.5000001 19.4996014,19.4985152 19.5,13.4955453 C19.5,13.4988424 14.5012367,13.5003274 4.50370996,13.5000001 C4.50163535,13.5000001 4.5003987,16.501485 4.50000002,22.5044549 Z M4.50000002,1.50000001 L4.50000002,7.50000001 L15,7.50000001 L15,1.50000001 L4.50000002,1.50000001 Z M12.0000001,3.00000001 L13.5000001,3.00000001 L13.5000001,6.00000001 L12.0000001,6.00000001 L12.0000001,3.00000001 Z" id="icons/ol/save_32"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
