<kendo-dialog
  [id]="'view-more-adjustment-modal'"
  class="view-more-adjustment-modal-container"
  *ngIf="showModal"
  (close)="closeHandler()">
  <kendo-dialog-titlebar>
    <div>
      <span class="title">View More</span>
    </div>
  </kendo-dialog-titlebar>
  <div class="add-adjustment-form">
    <p>
      <span class="text-bold">{{ adjustmentTypeTitle }}:</span>
      {{ adjustmentDetails.adjustmentTypeName }}
    </p>
    <p>
      <span class="text-bold">Adjustment Field:</span>
      {{ adjustmentDetails.fieldName }}
    </p>
    <p>
      <span class="text-bold">Adjustment Amount:</span>
      {{ adjustmentDetails.adjustmentAmount | currency }}
    </p>
    <br />
    <p class="adjustment-memo">
      <span class="text-bold">Adjustment Notes:</span>
      {{ adjustmentDetails.adjustmentMemo }}
    </p>
  </div>
  <kendo-dialog-actions [layout]="'end'">
    <button kendoButton class="app-button condensed outline" (click)="closeHandler()">Cancel</button>
  </kendo-dialog-actions>
</kendo-dialog>
