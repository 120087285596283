/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconSearchOutlineSvgIcon: SVGIcon = {
  name: 'search_outline',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Outline/search-outline</title><g id="Icon/Outline/search-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="search" transform="translate(0.000000, 0.000000)"><path d="M15.8767808,14.8063228 L24,22.9295421 L22.9295421,24 L14.8063228,15.8767808 C13.2390428,17.201437 11.2127786,18 9,18 C4.02943727,18 0,13.9705628 0,9 C0,4.02943727 4.02943727,0 9,0 C13.9705628,0 18,4.02943727 18,9 C18,11.2127786 17.201437,13.2390428 15.8767808,14.8063228 Z M9.0138562,16.5138562 C13.1559919,16.5138562 16.5138562,13.1559919 16.5138562,9.0138562 C16.5138562,4.87172059 13.1559919,1.5138562 9.0138562,1.5138562 C4.87172059,1.5138562 1.5138562,4.87172059 1.5138562,9.0138562 C1.5138562,13.1559919 4.87172059,16.5138562 9.0138562,16.5138562 Z" id="Combined-Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
