/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconTrashFillSvgIcon: SVGIcon = {
  name: 'trash_fill',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Fill/delete-fill</title><g id="Icon/Fill/delete-fill" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(0.750000, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="delete"><path d="M2.25000001,7.54655351 L20.25,7.54655351 L19.9874183,20.8137676 C19.9694084,21.6992491 19.5987069,22.4961824 19.010387,23.070995 C18.4235679,23.6458075 17.6191305,24.0000001 16.733649,24.0000001 L5.89025285,24.0000001 C5.66963288,24.0000001 5.45351537,23.9789887 5.24340112,23.935465 C4.82617426,23.8514193 4.43596207,23.6878304 4.09227519,23.4582055 C3.92118216,23.3441435 3.76059484,23.2150733 3.61351487,23.070995 C3.02669578,22.4961824 2.65599421,21.6992491 2.63798442,20.8137676 L2.25000001,7.54655351 Z M16.5,1.50081607 L20.999184,1.50081607 C21.8291353,1.50081607 22.5,2.17318168 22.5,3.00163215 C22.5,3.83008262 21.8291353,4.50244822 20.999184,4.50244822 L1.50081607,4.50244822 C0.670864788,4.50244822 0,3.83008262 0,3.00163215 C0,2.17318168 0.670864788,1.50081607 1.50081607,1.50081607 L6,1.50081607 C6,0.672365602 6.67086481,0 7.50081611,0 L14.999184,0 C15.8276344,0 16.5,0.672365602 16.5,1.50081607 Z" id="Combined-Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
