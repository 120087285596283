/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconFileFillSvgIcon: SVGIcon = {
  name: 'file_fill',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Fill/file-fill</title><g id="Icon/Fill/file-fill" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="file" transform="translate(0.000000, 3.000000)"><path d="M24,1.5 L24,18 L0,18 L0,0 L22.5,0 C23.3290001,0 24,0.671000005 24,1.5 Z M6.00000001,9.75000002 L6.00000001,8.25000002 L3.00000001,8.25000002 L3.00000001,6.75000002 L6.75000003,6.75000002 L6.75000003,5.25000002 L1.50000001,5.25000002 L1.50000001,12.75 L3.00000001,12.75 L3.00000001,9.75000002 L6.00000001,9.75000002 Z M9.75000003,12.75 L9.75000003,5.25000002 L8.25000003,5.25000002 L8.25000003,12.75 L9.75000003,12.75 Z M12.75,11.25 L12.75,5.25000002 L11.25,5.25000002 L11.25,12.75 L16.5000001,12.75 L16.5000001,11.25 L12.75,11.25 Z M21.7500001,9.75000002 L21.7500001,8.20157973 L19.5000001,8.20157973 L19.5000001,6.75000002 L22.5000001,6.75000002 L22.5000001,5.25000002 L18.0000001,5.25000002 L18.0000001,12.75 L22.4947574,12.75 L22.5000001,11.25 L19.5000001,11.25 L19.5000001,9.75000002 L21.7500001,9.75000002 Z" id="Combined-Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
