/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconCancelOutlineSvgIcon: SVGIcon = {
  name: 'cancel_outline',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Outline/circle-delete-outline</title><g id="Icon/Outline/circle-delete-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="circle-delete"><path d="M12,0 C18.6280001,0 24,5.37300002 24,12 C24,18.6270001 18.6280001,24 12,24 C5.37300002,24 0,18.6270001 0,12 C0,5.37300002 5.37300002,0 12,0 Z M12,22.5 C17.7900001,22.5 22.5,17.7900001 22.5,12 C22.5,6.21000003 17.7900001,1.50000001 12,1.50000001 C6.21000003,1.50000001 1.50000001,6.21000003 1.50000001,12 C1.50000001,17.7900001 6.21000003,22.5 12,22.5 Z M15.7503001,7.18950003 L16.8113001,8.25050006 L13.0613001,12.0005001 L16.8113001,15.7505001 L15.7503001,16.8105001 L12.0003001,13.0605001 L8.25030003,16.8105001 L7.18930003,15.7505001 L10.9393,12.0005001 L7.18930003,8.25050006 L8.25030003,7.18950003 L12.0003001,10.9395 L15.7503001,7.18950003 Z" id="Combined-Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
