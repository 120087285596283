/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconPlusFillSvgIcon: SVGIcon = {
  name: 'plus_fill',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Fill/plus-fill</title><g id="Icon/Fill/plus-fill" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><polygon id="Combined-Shape" points="10.5 10.5 10.5 0 13.5000001 0 13.5000001 10.5 24.0000001 10.5 24.0000001 13.5000001 13.5000001 13.5000001 13.5000001 24.0000001 10.5 24.0000001 10.5 13.5000001 0 13.5000001 0 10.5"/></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
