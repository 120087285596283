import { pwcIconAlertFillSvgIcon } from '@app-pwc-icons/fill-icons/alert-fill.svgicon';
import { pwcIconCalendarFillSvgIcon } from '@app-pwc-icons/fill-icons/calendar-fill.svgicon';
import { pwcIconCancelFillSvgIcon } from '@app-pwc-icons/fill-icons/cancel-fill.svgicon';
import { pwcIconCloseFillSvgIcon } from '@app-pwc-icons/fill-icons/close-fill.svgicon';
import { pwcIconDownChevronFillSvgIcon } from '@app-pwc-icons/fill-icons/down-chevron-fill.svgicon';
import { pwcIconEditFillSvgIcon } from '@app-pwc-icons/fill-icons/edit-fill.svgicon';
import { pwcIconExcelFileFillSvgIcon } from '@app-pwc-icons/fill-icons/excel-file-fill.svgicon';
import { pwcIconFileFillSvgIcon } from '@app-pwc-icons/fill-icons/file-fill.svgicon';
import { pwcIconFilterFillSvgIcon } from '@app-pwc-icons/fill-icons/filter-fill.svgicon';
import { pwcIconLogoutFillSvgIcon } from '@app-pwc-icons/fill-icons/logout-fill.svgicon';
import { pwcIconPdfFileFillSvgIcon } from '@app-pwc-icons/fill-icons/pdf-file-fill.svgicon';
import { pwcIconPencilFillSvgIcon } from '@app-pwc-icons/fill-icons/pencil-fill.svgicon';
import { pwcIconPlusFillSvgIcon } from '@app-pwc-icons/fill-icons/plus-fill.svgicon';
import { pwcIconRefreshFillSvgIcon } from '@app-pwc-icons/fill-icons/refresh-fill.svgicon';
import { pwcIconSaveFillSvgIcon } from '@app-pwc-icons/fill-icons/save-fill.svgicon';
import { pwcIconSearchFillSvgIcon } from '@app-pwc-icons/fill-icons/search-fill.svgicon';
import { pwcIconTrashFillSvgIcon } from '@app-pwc-icons/fill-icons/trash-fill.svgicon';
import { pwcIconUpChevronFillSvgIcon } from '@app-pwc-icons/fill-icons/up-chevron-fill.svgicon';
import { pwcIconCalendarOutlineSvgIcon } from '@app-pwc-icons/outline-icons/calendar-outline.svgicon';
import { pwcIconCancelOutlineSvgIcon } from '@app-pwc-icons/outline-icons/cancel-outline.svgicon';
import { pwcIconCloseOutlineSvgIcon } from '@app-pwc-icons/outline-icons/close-outline.svgicon';
import { pwcIconDownChevronOutlineSvgIcon } from '@app-pwc-icons/outline-icons/down-chevron-outline.svgicon';
import { pwcIconDownloadOutlineSvgIcon } from '@app-pwc-icons/outline-icons/download-outline.svgicon';
import { pwcIconEditOutlineSvgIcon } from '@app-pwc-icons/outline-icons/edit-outline.svgicon';
import { pwcIconExcelFileOutlineSvgIcon } from '@app-pwc-icons/outline-icons/excel-file-outline.svgicon';
import { pwcIconFileOutlineSvgIcon } from '@app-pwc-icons/outline-icons/file-outline.svgicon';
import { pwcIconFilterOutlineSvgIcon } from '@app-pwc-icons/outline-icons/filter-outline.svgicon';
import { pwcIconPdfFileOutlineSvgIcon } from '@app-pwc-icons/outline-icons/pdf-file-outline.svgicon';
import { pwcIconPencilOutlineSvgIcon } from '@app-pwc-icons/outline-icons/pencil-outline.svgicon';
import { pwcIconPersonOutlineSvgIcon } from '@app-pwc-icons/outline-icons/person-outline.svgicon';
import { pwcIconPlusOutlineSvgIcon } from '@app-pwc-icons/outline-icons/plus-outline.svgicon';
import { pwcIconRefreshOutlineSvgIcon } from '@app-pwc-icons/outline-icons/refresh-outline.svgicon';
import { pwcIconSaveOutlineSvgIcon } from '@app-pwc-icons/outline-icons/save-outline.svgicon';
import { pwcIconSearchOutlineSvgIcon } from '@app-pwc-icons/outline-icons/search-outline.svgicon';
import { pwcIconTrashOutlineSvgIcon } from '@app-pwc-icons/outline-icons/trash-outline.svgicon';
import { pwcIconUpChevronOutlineSvgIcon } from '@app-pwc-icons/outline-icons/up-chevron-outline.svgicon';

export const AppPwcIcons = {
  fill: {
    cancelIcon: pwcIconCancelFillSvgIcon,
    fileExcelIcon: pwcIconExcelFileFillSvgIcon,
    fileIcon: pwcIconFileFillSvgIcon,
    filePdfIcon: pwcIconPdfFileFillSvgIcon,
    filterIcon: pwcIconFilterFillSvgIcon,
    pencilIcon: pwcIconPencilFillSvgIcon,
    plusIcon: pwcIconPlusFillSvgIcon,
    saveIcon: pwcIconSaveFillSvgIcon,
    searchIcon: pwcIconSearchFillSvgIcon,
    trashIcon: pwcIconTrashFillSvgIcon,
    xIcon: pwcIconCloseFillSvgIcon,
    editIcon: pwcIconEditFillSvgIcon,
    refreshIcon: pwcIconRefreshFillSvgIcon,
    downChevronIcon: pwcIconDownChevronFillSvgIcon,
    upChevronIcon: pwcIconUpChevronFillSvgIcon,
    logoutIcon: pwcIconLogoutFillSvgIcon,
    alertIcon: pwcIconAlertFillSvgIcon,
    calendarIcon: pwcIconCalendarFillSvgIcon
  },
  outline: {
    cancelIcon: pwcIconCancelOutlineSvgIcon,
    fileExcelIcon: pwcIconExcelFileOutlineSvgIcon,
    fileIcon: pwcIconFileOutlineSvgIcon,
    filePdfIcon: pwcIconPdfFileOutlineSvgIcon,
    filterIcon: pwcIconFilterOutlineSvgIcon,
    pencilIcon: pwcIconPencilOutlineSvgIcon,
    plusIcon: pwcIconPlusOutlineSvgIcon,
    saveIcon: pwcIconSaveOutlineSvgIcon,
    searchIcon: pwcIconSearchOutlineSvgIcon,
    trashIcon: pwcIconTrashOutlineSvgIcon,
    xIcon: pwcIconCloseOutlineSvgIcon,
    editIcon: pwcIconEditOutlineSvgIcon,
    refreshIcon: pwcIconRefreshOutlineSvgIcon,
    downChevronIcon: pwcIconDownChevronOutlineSvgIcon,
    upChevronIcon: pwcIconUpChevronOutlineSvgIcon,
    personIcon: pwcIconPersonOutlineSvgIcon,
    downloadIcon: pwcIconDownloadOutlineSvgIcon,
    calendarIcon: pwcIconCalendarOutlineSvgIcon
  }
};
