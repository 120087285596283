/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconCalendarFillSvgIcon: SVGIcon = {
  name: 'calendar_fill',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Fill/calendar-fill</title><g id="Icon/Fill/calendar-fill" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="calendar" transform="translate(1.500000, 0.000000)"><path d="M0,22.5 L0,9.00000004 L21,9.00000004 L21,22.5 C21,23.3280001 20.3280001,24 19.5,24 L1.5,24 C0.672000003,24 0,23.3280001 0,22.5 Z M15.0000001,21.0000001 L18.0000001,21.0000001 L18.0000001,18.0000001 L15.0000001,18.0000001 L15.0000001,21.0000001 Z M15,0 L18,0 L18,3 L21,3 L21,6 L0,6 L0,3 L3,3 L3,0 L6,0 L6,3 L15,3 L15,0 Z" id="Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
