/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconRefreshOutlineSvgIcon: SVGIcon = {
  name: 'refresh_outline',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Outline/refresh-outline</title><g id="Icon/Outline/refresh-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="refresh" transform="translate(0.000000, 2.250000)"><path d="M5.73900002,4.86500002 L5.73900002,9.88100007 C5.73900002,14.5010001 9.49900001,18.2610001 14.119,18.2610001 C18.7400001,18.2610001 22.5,14.5010001 22.5,9.88100007 C22.5,5.26 18.7400001,1.5 14.119,1.5 L14.119,0 C19.5670001,0 24,4.43300002 24,9.88100007 C24,15.328 19.5670001,19.7610001 14.119,19.7610001 C8.67200006,19.7610001 4.23900002,15.328 4.23900002,9.88100007 L4.23900002,4.86500002 L4.23900002,4.86490002 L1.28000001,7.82390006 C0.987000004,8.11690001 0.512000002,8.11690001 0.219000001,7.82390006 C-0.0730000003,7.53090003 -0.0730000003,7.05690003 0.219000001,6.7639 L4.98900002,1.99390001 L9.75900004,6.7639 C10.051,7.05690003 10.051,7.53090003 9.75900004,7.82390006 C9.61200004,7.97090006 9.42000004,8.04390003 9.22800004,8.04390003 C9.03600004,8.04390003 8.84400004,7.97090006 8.69800001,7.82390006 L5.73900002,4.86490002 L5.73900002,4.86500002 Z M4.23900002,4.05800002 L5.73900002,4.05800002 L5.73900002,4.05790001 L4.23900002,4.05790001 L4.23900002,4.05800002 Z" id="Combined-Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
