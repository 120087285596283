import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation, EventEmitter, Output } from '@angular/core';

import { AppPwcIcons } from '@app-shared/icons/app-pwc.icons';

@Component({
  selector: 'pwc-fdm-alert-bar',
  templateUrl: './alert-bar.component.html',
  styleUrl: './alert-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AlertBarComponent {
  @Input() set alertFilename(value: string) {
    if (value) {
      this.filename = value;
    }
  }

  @Input() set alertMessage(value: string) {
    if (value) {
      this.message = value;
    }
  }

  @Output() doAction = new EventEmitter<boolean>();

  filename = 'Warning.xlsx';
  message = 'Your calculation has completed with the following warnings.';
  appPwcIcons = AppPwcIcons;

  downloadHandler(): void {
    this.doAction.emit(true);
  }
}
