/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconTrashOutlineSvgIcon: SVGIcon = {
  name: 'trash_outline',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Outline/delete-outline</title><g id="Icon/Outline/delete-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(0.750075, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="delete"><path d="M17.7263739,20.7839867 L17.9973751,7.55593151 L19.4973813,7.55593151 L19.2263802,20.8149868 C19.1903801,22.5849942 17.744374,24.0000001 15.9743666,24.0000001 L5.13732142,24.0000001 C3.36731404,24.0000001 1.92230802,22.5849942 1.88630787,20.8149868 L1.61430673,7.55593151 L3.11430673,7.55593151 L3.38531412,20.7839867 C3.4053142,21.7299906 4.19131748,22.4999939 5.13732142,22.4999939 L15.9743666,22.4999939 C16.9213705,22.4999939 17.7073738,21.7299906 17.7263739,20.7839867 Z M0.750003131,3.00000001 L20.2499251,3.00000001 C20.6649268,3.00000001 20.9999282,3.33500141 20.9999282,3.75000314 C20.9999282,4.16500487 20.6649268,4.50000001 20.2499251,4.50000001 L0.750003131,4.50000001 C0.335001399,4.50000001 0,4.16500487 0,3.75000314 C0,3.33500141 0.335001399,3.00000001 0.750003131,3.00000001 Z M14.9999219,1.5 L6.00000315,1.5 C5.58500142,1.5 5.25000002,1.16500486 5.25000002,0.750003131 C5.25000002,0.335001397 5.58500142,0 6.00000315,0 L14.9999219,0 C15.4149237,0 15.7499251,0.335001397 15.7499251,0.750003131 C15.7499251,1.16500486 15.4149237,1.5 14.9999219,1.5 Z" id="Combined-Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
