/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconFileOutlineSvgIcon: SVGIcon = {
  name: 'file_outline',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Outline/file-outline</title><g id="Icon/Outline/file-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="file" transform="translate(0.000000, 3.000000)"><path d="M22.5,0 C23.3280001,0 24,0.672000003 24,1.5 L24,18 L0,18 L0,0 L22.5,0 Z M1.50000001,16.5 L22.5,16.5 L22.5,1.50000001 L1.50000001,1.50000001 L1.50000001,16.5 Z M6.75000003,9.75000004 L4.50000001,9.75000004 L4.50000001,12 L3.00000001,12 L3.00000001,6.00000003 L7.50000001,6.00000003 L7.50000001,7.50000003 L4.50000001,7.50000003 L4.50000001,8.25000003 L6.75000003,8.25000003 L6.75000003,9.75000004 Z M10.5,12 L9.00000004,12 L9.00000004,6.00000003 L10.5,6.00000003 L10.5,12 Z M13.5000001,10.5 L15.7500001,10.5 L15.7500001,12 L12.0000001,12 L12.0000001,6.00000003 L13.5000001,6.00000003 L13.5000001,10.5 Z M20.2500001,9.75000004 L18.5786165,9.75000004 L18.5786165,10.5 L20.9960693,10.5 L20.9960693,12 L17.2500001,12 L17.2500001,6.00000003 L21.0000001,6.00000003 L21.0000001,7.50000003 L18.5786165,7.50000003 L18.5786165,8.25000003 L20.2500001,8.25000003 L20.2500001,9.75000004 Z" id="Combined-Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
