/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconPersonOutlineSvgIcon: SVGIcon = {
  name: 'person_outline',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Outline/person-outline</title><g id="Icon/Outline/person-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="person" transform="translate(0.000000, 1.500000)"><path d="M12.000282,12 C17.1072262,12 21.515178,14.1059002 23.5661556,17.1501514 C24.627144,18.7239032 23.619155,21 21.8731741,21 L2.12638988,21 C0.380408953,21 -0.626580043,18.7239032 0.433408374,17.1501514 C2.48438596,14.1059002 6.89333778,12 12.000282,12 Z M12.000282,13.5 C7.4756231,13.5 3.47799925,15.3156787 1.67752176,17.9881143 C1.25631327,18.6134774 1.64704233,19.5 2.12638988,19.5 L21.8731741,19.5 C22.3528661,19.5 22.7437035,18.6135598 22.3221455,17.9882677 C20.5214453,15.3155014 16.5247121,13.5 12.000282,13.5 Z M11.6260549,9.00092328 C9.14062203,9.00092328 7.1260549,6.98635623 7.1260549,4.5 C7.1260549,2.01456709 9.14062203,0 11.6260549,0 C14.1114878,0 16.1260549,2.01456709 16.1260549,4.5 C16.1260549,6.98635623 14.1114878,9.00092328 11.6260549,9.00092328 Z M11.6260658,1.50001087 C9.97135324,1.50001087 8.62606578,2.84618751 8.62606578,4.50001087 C8.62606578,6.15472337 9.97135324,7.50090003 11.6260658,7.50090003 C13.2807783,7.50090003 14.6260658,6.15472337 14.6260658,4.50001087 C14.6260658,2.84618751 13.2807783,1.50001087 11.6260658,1.50001087 Z" id="Combined-Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
