/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconCancelFillSvgIcon: SVGIcon = {
  name: 'cancel_fill',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Fill/circle-delete-fill</title><g id="Icon/Fill/circle-delete-fill" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, 0.000075)" fill="currentColor" fill-rule="nonzero"><g id="circle-delete" transform="translate(0.000150, 0.000000)"><path d="M11.9999251,0 C18.6268837,0 23.9998501,5.37296644 23.9998501,11.9999251 C23.9998501,18.6268837 18.6268837,23.9998501 11.9999251,23.9998501 C5.37196645,23.9998501 3.49592031e-16,18.6268837 3.49592031e-16,11.9999251 C3.49592031e-16,5.37296644 5.37196645,0 11.9999251,0 Z M17.8708884,15.7499016 L14.1209118,11.9999251 L17.8708884,8.24994847 L15.7499016,6.12896172 L11.9999251,9.8789383 L8.24994847,6.12896172 L6.12896172,8.24994847 L9.8789383,11.9999251 L6.12896172,15.7499016 L8.24994847,17.8708884 L11.9999251,14.1209118 L15.7499016,17.8708884 L17.8708884,15.7499016 Z" id="Combined-Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
