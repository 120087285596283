/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconCloseFillSvgIcon: SVGIcon = {
  name: 'close_fill',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Fill/close-fill</title><g id="Icon/Fill/close-fill" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><polygon id="Combined-Shape" points="12.0000001 9.27156821 21.2715683 0 24.0000001 2.7284318 14.7284319 12.0000001 23.9994001 21.2709683 21.2709683 23.9994001 12.0000001 14.7284319 2.72903178 23.9994001 0.000599985002 21.2709683 9.27156821 12.0000001 0 2.7284318 2.7284318 0"/></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
