/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconSearchFillSvgIcon: SVGIcon = {
  name: 'search_fill',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Fill/search-fill</title><g id="Icon/Fill/search-fill" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="search" transform="translate(0.000000, 0.000000)"><path d="M16.6856012,13.6856012 L24,21 L21,24 L13.6856012,16.6856012 C12.3207566,17.5194725 10.716495,18 9,18 C4.02943727,18 0,13.9705628 0,9 C0,4.02943727 4.02943727,0 9,0 C13.9705628,0 18,4.02943727 18,9 C18,10.716495 17.5194725,12.3207566 16.6856012,13.6856012 Z M9.00000002,13.5 C11.4852814,13.5 13.5,11.4852814 13.5,9.00000002 C13.5,6.51471865 11.4852814,4.50000002 9.00000002,4.50000002 C6.51471865,4.50000002 4.50000002,6.51471865 4.50000002,9.00000002 C4.50000002,11.4852814 6.51471865,13.5 9.00000002,13.5 Z" id="Combined-Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
