/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconPlusOutlineSvgIcon: SVGIcon = {
  name: 'plus_outline',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Outline/plus-outline</title><g id="Icon/Outline/plus-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><polygon id="Combined-Shape" points="11.25 11.25 11.25 0 12.7500001 0 12.7500001 11.25 24.0000001 11.25 24.0000001 12.7500001 12.7500001 12.7500001 12.7500001 24.0000001 11.25 24.0000001 11.25 12.7500001 0 12.7500001 0 11.25"/></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
