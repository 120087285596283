<kendo-daterange [style]="'min-width:200px;'">
  <kendo-dateinput
    class="range-filter"
    kendoDateRangeStartInput
    [value]="start"
    (valueChange)="filterRange($event, end)"
    [min]="minDate"
    [max]="maxDate"
    [format]="'yyyy/MM/dd'">
  </kendo-dateinput>
  -
  <kendo-dateinput
    class="range-filter"
    kendoDateRangeEndInput
    [value]="end"
    (valueChange)="filterRange(start, $event)"
    [min]="minDate"
    [max]="maxDate"
    [format]="'yyyy/MM/dd'">
  </kendo-dateinput>
</kendo-daterange>
@if (hasFilter) {
  <button kendoButton [svgIcon]="xIcon" title="Clear" (click)="clearFilter()"></button>
}
