/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconPencilFillSvgIcon: SVGIcon = {
  name: 'pencil_fill',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Fill/pencil-fill</title><g id="Icon/Fill/pencil-fill" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="Pencil-fill"><path d="M21.9697007,5.74472133 L18.2552788,2.0302994 L19.8462449,0.43933319 C20.4320225,-0.146444397 21.3817557,-0.146444397 21.9675333,0.43933319 L23.5606669,2.03246683 C24.1464445,2.61824441 24.1464445,3.56797753 23.5606669,4.15375511 L21.9697007,5.74472133 Z M20.9090565,6.80536547 L5.40279282,22.3116292 L0,24.0000001 L1.68837089,18.5972073 L17.1946346,3.09094355 L20.9090565,6.80536547 Z" id="Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
