/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconLogoutFillSvgIcon: SVGIcon = {
  name: 'logout_fill',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Fill/logout-fill</title><g id="Icon/Fill/logout-fill" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="logout"><path d="M18.7785001,6.78315003 L24.0000001,12.0046501 L18.7875001,17.2171501 C18.2025001,17.8021501 17.2530001,17.8021501 16.6665001,17.2171501 C16.0815001,16.6306501 16.0815001,15.6811501 16.6665001,15.0961501 L18.2580001,13.5046501 L9.85950004,13.5046501 L9.85950004,10.50465 L18.2580001,10.50465 L16.6575001,8.90415004 C16.0710001,8.31915003 16.0710001,7.36965003 16.6575001,6.78315003 C17.2440001,6.19665003 18.1935001,6.19665003 18.7785001,6.78315003 Z M12,16.5 L12.3525001,16.5 L15,19.1475001 L15,22.5 C15,23.3280001 14.3280001,24 13.5,24 L1.5,24 C0.672000003,24 -4.49812237e-25,23.3280001 -4.49812237e-25,22.5 L-4.49812237e-25,1.5 C-4.49812237e-25,0.672000003 0.672000003,0 1.5,0 L13.5,0 C14.3280001,0 15,0.672000003 15,1.5 L15,4.85250002 L12.3525001,7.5 L12,7.5 L12,3 L3,3 L3,21 L12,21 L12,16.5 Z" id="Logout"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
