/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconEditFillSvgIcon: SVGIcon = {
  name: 'edit_fill',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Fill/edit-fill</title><g id="Icon/Fill/edit-fill" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, 0.000112)" fill="currentColor" fill-rule="nonzero"><g id="edit"><path d="M7.50015472,13.4998735 L10.5001266,16.4998454 L21.7497962,5.2499508 L21.7497962,22.5498366 C21.7497962,23.3504365 21.1006076,23.9996251 20.3000077,23.9996251 L0,23.9996251 L0,3.6996174 C0,2.89901749 0.649188566,2.24982892 1.44978849,2.24982892 L18.7500493,2.24982892 L7.50015472,13.4998735 Z M6.00016877,17.9998313 L9.00014066,17.9998313 L6.00016877,14.9998594 L6.00016877,17.9998313 Z M21.0000282,0 L24.0000001,2.99997189 L21.7500212,5.2499508 L18.7500493,2.24997892 L21.0000282,0 Z" id="Edit"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
