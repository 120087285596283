import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'fdmPercentage'
})
export class FdmPercentagePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number, digitsInfo?: string, locale?: string): string {
    const defaultFormat = '1.2-2';
    const result = this.decimalPipe.transform(value, digitsInfo ?? defaultFormat, locale);
    return result ? `${result}` : '';
  }
}
