import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationStateService {
  //FIXME Needs to be fixed when the breadcrumb is replaced with Kendo component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private state: any;

  //FIXME Needs to be fixed when the breadcrumb is replaced with Kendo component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, 	@typescript-eslint/explicit-module-boundary-types
  setNavigationState(state: any): void {
    this.state = state;
  }

  //FIXME Needs to be fixed when the breadcrumb is replaced with Kendo component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getNavigationState(): any {
    return this.state;
  }
}
