/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconSaveFillSvgIcon: SVGIcon = {
  name: 'save_fill',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Fill/save-fill</title><g id="Icon/Fill/save-fill" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="save"><path d="M1.5,0 L17.3786797,0 C17.7765045,0 18.1580354,0.158035261 18.4393399,0.43933983 L23.5606603,5.56066019 C23.8419648,5.84196476 24,6.22349564 24,6.62132037 L24,22.5 C24,23.3284272 23.3284272,24 22.5,24 L1.5,24 C0.671572878,24 0,23.3284272 0,22.5 L0,1.5 C0,0.671572878 0.671572878,0 1.5,0 Z M3.00000001,1.50000001 L3.00000001,6.00000001 C3.00000001,6.82842715 3.67157289,7.50000001 4.50000001,7.50000001 L13.5,7.50000001 C14.3284272,7.50000001 15,6.82842715 15,6.00000001 L15,1.50000001 L3.00000001,1.50000001 Z M4.50000001,12.0000001 C3.67157289,12.0000001 3.00000001,12.6715729 3.00000001,13.5000001 L3.00000001,22.5000001 L4.50000001,22.5000001 L4.50000001,13.75 L19.5,13.75 L19.5,22.5000001 L21,22.5000001 L21,13.5000001 C21,12.6715729 20.3284272,12.0000001 19.5,12.0000001 L4.50000001,12.0000001 Z M12.0000001,3.00000001 L13.5000001,3.00000001 L13.5000001,6.00000001 L12.0000001,6.00000001 L12.0000001,3.00000001 Z" id="Combined-Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
