import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { NotificationModel } from '@app-shared/models/notification.model';
import { NotificationService } from '@appkit4/angular-components/notification';

@Component({
  selector: 'pwc-fdm-notification',
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent {
  @Input() set notification(value: NotificationModel) {
    if (value) {
      this.notificationValue = value;
      this.createNotification();
    }
  }

  notificationValue: NotificationModel = {} as NotificationModel;

  constructor(protected notificationSvcValue: NotificationService) {}

  createNotification(): void {
    this.notificationSvcValue.show(
      this.notificationValue.title,
      this.notificationValue.message || '',
      this.notificationValue.hyperLink || '',
      this.notificationValue.hyperLinkHref || '',
      {
        duration: 1000,
        id: this.notificationValue.id,
        clickToClose: false,
        showClose: true,
        icon: this.notificationValue.icon
      },
      this.notificationValue.showTimed,
      this.notificationValue.showExpandedIcon
    );
  }
}
