/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconCalendarOutlineSvgIcon: SVGIcon = {
  name: 'calendar_outline',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Outline/calendar-outline</title><g id="Icon/Outline/calendar-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="calendar" transform="translate(1.500000, 0.000000)"><path d="M21,7.5 L21,9 L21,22.5 C21,23.3280001 20.3280001,24 19.5,24 L1.5,24 C0.672000003,24 0,23.3280001 0,22.5 L0,3 L3.75000002,3 L3.75000002,0 L5.25000002,0 L5.25000002,3 L15.7500001,3 L15.7500001,0 L17.2500001,0 L17.2500001,3 L21,3 L21,7.5 Z M1.50000001,7.50000002 L19.5,7.50000002 L19.5,4.50000002 L1.50000001,4.50000002 L1.50000001,7.50000002 Z M1.50000001,22.5 L19.5,22.5 L19.5,9.00000004 L1.50000001,9.00000004 L1.50000001,22.5 Z M16.5000001,21.0000001 L16.5000001,19.5000001 L18.0000001,19.5000001 L18.0000001,21.0000001 L16.5000001,21.0000001 Z" id="ic_ol_Calendar"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
