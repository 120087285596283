/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconRefreshFillSvgIcon: SVGIcon = {
  name: 'refresh_fill',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Fill/refresh-fill</title><g id="Icon/Fill/refresh-fill" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="refresh" transform="translate(0.000000, 2.250000)"><path d="M13.9782088,0 C13.1502261,0 12.4782401,0.671986 12.4782401,1.49996875 C12.4782401,2.32795151 13.1502261,2.99993751 13.9782088,2.99993751 C17.8501282,2.99993751 21.0000626,6.14987193 21.0000626,10.0217913 C21.0000626,13.8927106 17.8501282,17.042645 13.9782088,17.042645 C10.1062895,17.042645 6.9563551,13.8927106 6.9563551,10.0217913 L6.9563551,7.27084856 L8.35232606,8.66681951 C8.64531994,8.95981339 9.02931191,9.10681031 9.41330396,9.10681031 C9.79729594,9.10681031 10.1802879,8.95981339 10.4732818,8.66681951 C11.0592696,8.08183166 11.0592696,7.13185143 10.4732818,6.54586368 L5.45638635,1.52896816 L0.439490846,6.54586368 C-0.146496949,7.13185143 -0.146496949,8.08183166 0.439490846,8.66681951 C1.02547864,9.25280726 1.97445887,9.25280726 2.56044667,8.66681951 L3.95641759,7.27084856 L3.95641759,10.0217913 C3.95641759,15.5476762 8.45232394,20.0425826 13.9782088,20.0425826 C19.5040937,20.0425826 24,15.5476762 24,10.0217913 C24,4.49590635 19.5040937,0 13.9782088,0" id="Fill-1"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
