<kendo-dialog [id]="appModalId" class="confirmation-modal" *ngIf="showModal">
  <kendo-dialog-titlebar>
    <div>
      <kendo-badge-container>
        <kendo-badge
          [rounded]="'full'"
          [fill]="badge.isFilled ? 'solid' : 'outline'"
          class="app-badge"
          [ngClass]="getBadgeClasses()"
          [align]="align"
          [position]="'inside'">
          {{ badge.value }}
        </kendo-badge>
      </kendo-badge-container>
      <span class="title">{{ title }}</span>
    </div>
  </kendo-dialog-titlebar>
  <ng-content></ng-content>
  <kendo-dialog-actions [layout]="'end'">
    <button kendoButton class="app-button condensed outline" (click)="closeHandler()">{{ cancelButtonLabel }}</button>
    @if (displaySaveButtonHandler()) {
      <button kendoButton class="app-button condensed solid" (click)="saveHandler()">{{ successButtonLabel }}</button>
    }
  </kendo-dialog-actions>
</kendo-dialog>
