/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconAlertFillSvgIcon: SVGIcon = {
  name: 'alert_fill',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Fill/alert-fill</title><g id="Icon/Fill/alert-fill" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, 1.500000)" fill="currentColor" fill-rule="nonzero"><g id="alert"><path fill="#ffbf1f" d="M23.8063295,18.8687078 C24.3568215,19.8152873 23.6683314,21 22.5658474,21 L1.43415273,21 C0.331668656,21 -0.356821394,19.8152873 0.19367065,18.8687078 L10.759518,0.711050869 C11.31151,-0.237016956 12.6884901,-0.237016956 13.2404821,0.711050869 L23.8063295,18.8687078 Z M12,18.0000001 C12.8277488,18.0000001 13.5,17.3293194 13.5,16.5000001 C13.5,15.6722513 12.8277488,15.0000001 12,15.0000001 C11.1722513,15.0000001 10.5,15.6722513 10.5,16.5000001 C10.5,17.3293194 11.1722513,18.0000001 12,18.0000001 Z M10.5000217,12 L13.4999784,12 L13.4999784,6.00000002 L10.5000217,6.00000002 L10.5000217,12 Z" id="Combined-Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
