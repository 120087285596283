import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { SVGIcon, xIcon } from '@progress/kendo-svg-icons';

interface CompositeFilterDescriptor {
  logic: 'or' | 'and';
  // NOTE Do not remove this line as the filters can be any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters: any[];
}

@Component({
  selector: 'pwc-fdm-filter-date-range',
  templateUrl: './filter-date-range.component.html',
  styleUrls: ['./filter-date-range.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterDateRangeComponent extends BaseFilterCellComponent {
  @Input() override filter = {} as CompositeFilterDescriptor;
  @Input() field = '';
  @Input() minDate: Date = new Date(1900, 1, 1);
  @Input() maxDate: Date = new Date(2099, 12, 31);

  xIcon: SVGIcon = xIcon;

  constructor(filterService: FilterService) {
    super(filterService);
  }

  get start(): Date {
    const first = this.findByOperator('gte');

    return (first || ({} as FilterDescriptor)).value;
  }

  get end(): Date {
    const end = this.findByOperator('lte');
    return (end || ({} as FilterDescriptor)).value;
  }

  get hasFilter(): boolean {
    return this.filtersByField(this.field).length > 0;
  }

  clearFilter(): void {
    this.filterService.filter(this.removeFilter(this.field));
  }

  filterRange(start: Date, end: Date): void {
    this.filter = this.removeFilter(this.field);

    const filters = [];

    if (start) {
      filters.push({
        field: this.field,
        operator: 'gte',
        value: start
      });
    }

    if (end) {
      filters.push({
        field: this.field,
        operator: 'lte',
        value: end
      });
    }

    const root = this.filter || {
      logic: 'and',
      filters: []
    };

    if (filters.length) {
      root.filters.push(...filters);
    }

    this.filterService.filter(root);
  }

  private findByOperator(op: string): FilterDescriptor {
    return this.filtersByField(this.field).filter(({ operator }) => operator === op)[0];
  }
}
