<div id="alert-bar">
  <div class="content">
    <kendo-svgicon [icon]="appPwcIcons.fill.alertIcon" size="large" class="icon-spacing"></kendo-svgicon>
    <span class="bold-text">Warning: </span>
    <span>{{ message }}</span>
    <span (click)="downloadHandler()" class="clickable-link"
      ><kendo-svgicon [icon]="appPwcIcons.outline.downloadIcon" size="large" class="icon-spacing"></kendo-svgicon
    ></span>
    <span (click)="downloadHandler()" class="warning-color bold-text clickable-link">Warnings_{{ filename }}.xlsx</span>
  </div>
</div>
