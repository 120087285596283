import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';

interface CompositeFilterDescriptor {
  logic: 'or' | 'and';
  // NOTE Do not remove this line as the filters can be any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters: any[];
}

@Component({
  selector: 'pwc-fdm-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterDropdownComponent extends BaseFilterCellComponent {
  get selectedValue(): unknown {
    const filter = this.filterByField(this.valueField);
    if (filter) {
      return filter.value;
    }
    return null;
  }

  @Input() override filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  @Input() data: unknown[] = [];
  @Input() textField = '';
  @Input() valueField = '';
  @Input() defaultValue = '';

  get defaultItem(): Record<string, unknown> {
    return {
      [this.textField]: this.defaultValue,
      [this.valueField]: null
    };
  }

  constructor(filterService: FilterService) {
    super(filterService);
  }

  onChange(value: unknown): void {
    this.applyFilter(this.applyFilterChanges(value));
  }
  private applyFilterChanges(value: unknown): CompositeFilterDescriptor {
    if (value === null) {
      return this.removeFilter(this.valueField);
    } else {
      return this.updateFilter({
        field: this.valueField,
        operator: 'eq',
        value: value
      });
    }
  }
}
