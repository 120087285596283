import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { BadgeModel } from '@app-shared/models/badges.model';
import { BadgeAlign } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'pwc-fdm-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmationModalComponent {
  @Input() title = '';
  @Input() showModal = false;
  @Input() successButtonLabel = '';
  @Input() cancelButtonLabel = '';
  @Input() badge: BadgeModel = {} as BadgeModel;

  @Output() doSuccess = new EventEmitter<boolean>();

  appModalId = `${'confirmation-modal'}${Math.random()}`;

  saveHandler(): void {
    this.showModal = false;
    this.doSuccess.emit(true);
  }

  closeHandler(): void {
    this.showModal = false;
    this.doSuccess.emit(false);
  }

  displaySaveButtonHandler(): boolean {
    return this.successButtonLabel.length > 0;
  }

  getBadgeClasses(): Record<string, boolean> {
    return {
      [this.badge.tagType]: !!this.badge.tagType
    };
  }

  get align(): BadgeAlign {
    return { vertical: 'top', horizontal: 'start' };
  }
}
