/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconCloseOutlineSvgIcon: SVGIcon = {
  name: 'close_outline',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Outline/close-outline</title><g id="Icon/Outline/close-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(0.750925, 0.749075)" fill="currentColor" fill-rule="nonzero"><polygon id="close-outline" points="11.2703555 10.2291233 21.4772728 0.0222059896 22.4981496 1.04308277 12.2912323 11.25 22.4796446 21.4384123 21.4569173 22.4611396 11.268505 12.2727273 1.02087678 22.5203556 0 21.4994788 10.2476283 11.2518506 0.0185049901 1.02272728 1.04123227 0"/></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
