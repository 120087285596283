/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconFilterOutlineSvgIcon: SVGIcon = {
  name: 'filter_outline',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Outline/filter-outline</title><g id="Icon/Outline/filter-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="filter"><path d="M0,0 L0,6.00150003 L7.5,10.5 L8.08350003,15.7500001 L8.25000003,17.2500001 L9,24 L15,24 L15.7500001,17.2500001 L15.9165001,15.7500001 L16.5,10.5 L24,6.00150003 L24,0 L0,0 Z M1.50000001,1.50000001 L22.5,1.50000001 L22.5,5.25000002 L1.50000001,5.25000002 L1.50000001,1.50000001 Z M8.90850004,9.59550004 L4.16550002,6.75000003 L19.8345001,6.75000003 L15.0915001,9.59550004 L14.4075001,15.75 L9.59250004,15.75 L8.90850004,9.59550004 Z M9.75900004,17.2500001 L14.2410001,17.2500001 L13.6575001,22.5000001 L10.3425,22.5000001 L9.75900004,17.2500001 Z" id="Page-1"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
